
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import ToastService from "../../services/helper/ToastService";
import Multiselect from "vue-multiselect";

import DisciplineService from "../../services/sporteventCalculation/DisciplineService";
import UnitService from "../../services/sporteventCalculation/UnitService";
import DisciplineCategoryService from "../../services/sporteventCalculation/DisciplineCategoryService";
import JudgeTypeService from "../../services/sporteventCalculation/JudgeTypeService";
import Discipline from "../../models/sporteventCalculation/Discipline";
import Unit from "../../models/sporteventCalculation/Unit";
import DisciplineCategory from "../../models/sporteventCalculation/DisciplineCategory";
import JudgeType from "../../models/sporteventCalculation/JudgeType";

@Component({
  components: {
    Multiselect,
  },
})
export default class UnitComponent extends Vue {
  public discipline: Discipline = new Discipline();
  public allUnits: Unit[] = [];
  public allDisciplineCategories: DisciplineCategory[] = [];
  public allJudgeTypes: JudgeType[] = [];

  mounted(): void {
    UnitService.getAll().then((items: Unit[]) => {
      this.allUnits = items;
    });
    DisciplineCategoryService.getAll().then((items: DisciplineCategory[]) => {
      this.allDisciplineCategories = items;
    });
    JudgeTypeService.getAll().then((items: JudgeType[]) => {
      this.allJudgeTypes = items;
    });

    if (
      this.$route.params.id !== undefined &&
      this.$route.params.id !== "new"
    ) {
      DisciplineService.get(this.$route.params.id).then((item: Discipline) => {
        this.discipline = item;
      });
    }
  }

  save(): void {
    this.discipline.unit = new Unit(this.discipline.unit);
    this.discipline.disciplineCategory = new DisciplineCategory(
      this.discipline.disciplineCategory
    );
    this.discipline.judgeType = new JudgeType(this.discipline.judgeType);
    //console.log("save", this.discipline);
    DisciplineService.save(this.discipline).then((item: Discipline) => {
      ToastService.Success(i18n.tc("labels.saved"), item.name);
      if (item) {
        this.discipline = item;
        this.handleBack();
      }
    });
  }

  handleBack(): void {
    this.$router.back();
  }

  getUnitName(unit: Unit): string {
    return unit.name;
  }

  getDisciplineCategoryName(disciplineCategory: DisciplineCategory): string {
    return disciplineCategory.name;
  }

  getJudgeTypeName(judgeType: JudgeType): string {
    return judgeType.name;
  }
}
